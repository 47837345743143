import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';

import * as serviceWorker from './serviceWorker';

// Context Providers
import { AppProvider } from './context/AppContext';
import { UserProvider } from './context/UserContext';

// Styles
import './styles/global.css';
import './styles/misc.css';
import './styles/mui.alt.css';
import './styles/noauth.css';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <UserProvider>
                <AppProvider> 
                    <App />
                </AppProvider>
            </UserProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();