import { LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    loader: {
        position: 'absolute',
        top: 64, height: 4,
        left: 0,
        right: 0
    }
}))

export default function Loader({show}) {

    const classes = useStyles();

    return (
        <LinearProgress className={classes.loader} style={{visibility: show ? 'visible' : 'hidden'}} />
    )
}
