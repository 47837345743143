
export default function commonStyles(theme) {
    return ({
        tabIndicator: {
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
            height: 3,
            padding: '0 4px',
            marginLeft: 0
        },

        middleColor: theme.palette.type === 'light' ? '#fff' : '#2b2b2b',
        middleColor2: theme.palette.type === 'light' ? '#fff' : '#484848',
        // middleColor3: theme.palette.type === 'light' ? '#fff' : '#313131',

        paperOveride: theme.palette.type === 'light' ? '#f9f9f9' : '#2b2b2b',

        body: theme.palette.background.default ?? (theme.palette.type === 'dark' ? theme.palette.background.default : '#f4f4f4') 
    })
}