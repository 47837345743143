import { atom } from 'recoil'

export const citiesAtom = atom({
    key: 'cities',
    initialValue: []
})

export const citiesLookupAtom = atom({
    key: 'citiesLookup',
    initialValue: {}
})

export const noticeTypesAtom = atom({
    key: 'noticeTypes',
    initialValue: []
})

export const studentProfileAtom = atom({
    key: 'studentProfile',
    initialValue: null
})

export const headerLoadingAtom = atom({
    key: 'headerLoading',
    initialValue: false
})

export const overviewGradeIdAtom = atom({
    key: 'overviewGradeId',
    default: null
})
