import {
    AppBar,
    Avatar, Box,
    IconButton,
    LinearProgress, makeStyles, Toolbar,
    Typography
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useRecoilValue } from 'recoil';
import { useApp } from '../../context/AppContext';
import { useUser } from '../../context/UserContext';
import { headerLoadingAtom } from '../../recoil/atoms';
import { searchBarTheme } from '../../utils/theme';
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';


const lightDark = (theme, light, dark) => (theme.palette.theme === 'light') ? light : dark;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 11,
        '& svg': {
            fill: (theme.palette.type === 'light') ? 'white' : null,
        },
        backgroundColor: (theme.palette.type === 'light') ? theme.palette.primary.main : theme.palette.background.paper,
        // background:'linear-gradient(120deg, var(--c1), var(--c2))',
        boxShadow: '0 1px 6px rgba(0, 0, 0, 0.21)'
    },
    root: {
        padding: '0 7px 0 14px',
        zIndex: theme.zIndex.drawer + 11,
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        height: 64,
    },
    beta: {
        marginLeft: 5,
        fontSize: '0.67rem',
        borderRadius: 6
    },
    menu: {
        marginTop: 34,
        minWidth: 360,
        top: '5em',
        '& li p': {
            marginLeft: 12
        },
        '& > *': {
            borderRadius: 0,
        }
    },
    menuItem: {
        minHeight: 50,
       
    },
    sidebar: {
        paddingTop: 70,
        width: 300
    },
    searchBar: searchBarTheme(theme),

    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        backgroundColor: (theme.palette.type === 'light') ? 'white' : theme.palette.primary.main,
        color: (theme.palette.type === 'light') ? theme.palette.primary.main : 'white',
    },

    title: {
        color: 'white'
    },
    menuToggler: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },

    loading: {
        position: 'fixed',
        top: 62,
        left: 0,
        right: 0
    }
}))


export default function Header() {

    const classes = useStyles();
    const history = useHistory();
    const { toggleOpenSidebar } = useApp();
    const { user, loggedIn } = useUser();
   
    const [showMenu, toggleShowMenu] = useToggle(false);
    const headerLoading = useRecoilValue(headerLoadingAtom);

    return (<>

        <AppBar elevation={0} className={classes.appBar}  position="relative"  >
            <Toolbar variant="regular"  className={classes.root}  >

                <Box display="flex" alignItems="center" >
                    {(user?.is_admin === true) && <IconButton style={{marginRight: 11}} className={classes.menuToggler} onClick={() => toggleOpenSidebar()} children={<MenuIcon />} />}
                    {(user?.is_admin !== true) && <Typography  className={classes.title} variant="h5" component="span"  > <Logo />
                        {/* <Tooltip placement="right" title="To je BETA, kar pomen da bo vse pokvarjeno, nedokončano, grdo, neurejeno in komaj delujoče :)"  >
                            <span className={classes.beta} >2.0 BETA</span> 
                        </Tooltip> */}

                    </Typography>}
                </Box>
                
                <Box display="flex" visibility={loggedIn ? 'visible' : 'hidden'} >
                    <Typography variant="caption"  >{process.env.NODE_ENV === 'development' ? process.env.NODE_ENV : ''}</Typography>
                    {<IconButton  onClick={() => history.push('/pomoc')} children={<HelpOutlineIcon />} />}
                    <IconButton size="small"  onClick={() => toggleShowMenu()}  children={<Avatar className={classes.avatar} >{user?.first_name[0]}</Avatar>} />
                </Box>
            </Toolbar>

            <LinearProgress hidden={!headerLoading} className={classes.loading} />
        </AppBar>

        {loggedIn &&
            <HeaderMenu drawerProps={{
                open: loggedIn && showMenu,
                onClose: toggleShowMenu
            }} />
        }

    </>)
}
