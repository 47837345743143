
const pudTheme = {
    showBottomNavAt: 500,
    allowClosingDrawerAt: 1000
}

const pudPallete = {
    mediumShade: '#323232'
}   

const searchBarTheme = (theme) => ({
    backgroundColor: theme.palette?.divider,
    borderRadius: 6,
    padding: '4px 16px',
    display: 'flex',
})

export default pudTheme;
export {
    pudPallete,
    searchBarTheme
}