import { useMediaQuery } from '@material-ui/core';
import React, { createContext, useContext, useState } from 'react';
import { useLocalStorage, useToggle } from 'react-use';
import lsKeys from '../utils/localStorageKeys';

export const AppContext = createContext(null);

export function AppProvider({ children }) {


    // Preferences
    const bUserPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') ?? false;

    const [darkMode, setDarkMode] = useLocalStorage( lsKeys.appSettings.bDarkMode, bUserPrefersDarkMode );
    const [oledDarkMode, setOledDarkMode] = useLocalStorage( lsKeys.appSettings.bOledDarkMode, false );
    const [disableAnims, setDisableAnims] = useLocalStorage( lsKeys.appSettings.bDisableAnims, false );
    const [experimentalFeatures, setExperimentalFeatures] = useLocalStorage( lsKeys.appSettings.bExperimentalFeatures, false );
    const [fastLoad, setFastLoad] = useLocalStorage( lsKeys.appSettings.bFastLoad, false );
    const [noticeTypeDescriptions, setNoticeTypeDescriptions] = useLocalStorage( lsKeys.appSettings.bNoticeTypeDescriptions, true );
    const [denseTables, setDenseTables] = useLocalStorage( lsKeys.appSettings.bDenseTables, true );
    const [collapsedSidebar, setCollapsedSidebar] = useLocalStorage( lsKeys.appSettings.bCollapsedSidebar, false );

    const [headerTitle, setHeaderTitle] = useState('');
    const [showSidebar, toggleOpenSidebar] = useToggle(false);

    const [selectedSchoolYear, setSelectedSchoolYear] = useLocalStorage( lsKeys.appSettings.schoolYear, null );

    return (
        <AppContext.Provider value={{
            darkMode, setDarkMode,
            disableAnims, setDisableAnims,
            oledDarkMode, setOledDarkMode,
            experimentalFeatures, setExperimentalFeatures,
            headerTitle, setHeaderTitle,
            showSidebar, toggleOpenSidebar,
            fastLoad, setFastLoad,
            noticeTypeDescriptions, setNoticeTypeDescriptions,
            denseTables, setDenseTables,
            collapsedSidebar, setCollapsedSidebar,
            
            selectedSchoolYear, setSelectedSchoolYear
        }}>
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);