import JwtDecode from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useInterval, useToggle } from 'react-use';
import { client } from '../utils/client';
import lsKeys from '../utils/localStorageKeys';


export const userTypes = {
    guest: 0,
    student: 100,
    admin: 200,
    super: 300,
    dev: 666
}

export const UserContext = createContext(null);

export function UserProvider({ children }) {

    const [user, setUser] = useState(null);

    const [userType, setUserType] = useState(0);
    const [token, setToken] = useState('');
    const [loggedIn, toggleLoggedIn] = useToggle(false);
    const [ready, toggleReady] = useToggle(false);

    function checkTokenValidation() {
        let tempToken = token || localStorage.getItem(lsKeys.auth.token);

        if(tempToken) {
            let decoded = JwtDecode(tempToken);

            if(Date.now() > decoded.exp * 1000) {
                setToken('');
                toggleLoggedIn(false)
                localStorage.removeItem(lsKeys.auth.token);
                client.refresh(1)
            }
        } 
    }

    useInterval(checkTokenValidation, 1000 * 30);

    // Decode token everytime it's changed
    useEffect(() => {

        let tk = localStorage.getItem(lsKeys.auth.token);
        if(tk) {

            let decoded = JwtDecode(tk);

            if(Date.now() > decoded.exp * 1000) {
                setToken('');
                toggleLoggedIn(false)
                localStorage.removeItem(lsKeys.auth.token);
                client.refresh(1)
            } else {
                setToken(tk);
                toggleLoggedIn(true)
                setUser(JwtDecode(tk));
            }
            
        } else {
            toggleLoggedIn(false);
        }

        setTimeout(() => toggleReady(true), 1);
    }, [])

    // Update individual states when a user logs in.
    useEffect(() => {
        setUserType(userTypes.dev);
    }, [])

    return (
        <UserContext.Provider value={{
            user, setUser, userType, setUserType,
            token, setToken,
            loggedIn, ready, checkTokenValidation
        }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext);