import React, { lazy, Suspense, useEffect, useMemo, useRef } from 'react';
import {
    BrowserRouter, Redirect, Route, Switch
} from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { createMuiTheme, CssBaseline, IconButton, makeStyles, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { useApp } from './context/AppContext';
import { useUser } from './context/UserContext';
import { citiesAtom, citiesLookupAtom } from './recoil/atoms';
import useApi from './services/useApi';

import CloseIcon from '@material-ui/icons/Close';
import Loader from './components/loading/Loader';
import LoadingApp from './components/loading/LoadingApp';
import TopNoticeRow from './components/misc/TopNoticeRow';
import Header from './components/nav/Header';
import Sidebar from './components/nav/Sidebar';
import commonStyles from './styles/commonStyle';

// Lazy Public Views
const PublicLogin = lazy(() => import('./pages/public/PublicLogin'));
const PublicPasswordReset = lazy(() => import('./pages/public/PublicPasswordReset'));
const PublicConfirmPasswordReset = lazy(() => import('./pages/public/PublicConfirmPasswordReset'));
const PublicCompanies = lazy(() => import('./pages/public/PublicCompanies'));

// Lazy Admin Views
const AdminPrograms = lazy(() => import('./pages/admin/AdminPrograms'));
const AdminSchools = lazy(() => import('./pages/admin/AdminSchools'));
const AdminGrades = lazy(() => import('./pages/admin/AdminGrades'));
const AdminStudents = lazy(() => import('./pages/admin/AdminStudents'));
const AdminCompanies = lazy(() => import('./pages/admin/AdminCompanies'));
const AdminEmployees = lazy(() => import('./pages/admin/AdminEmployees'));
const AdminModerators = lazy(() => import('./pages/admin/AdminModerators'));
const AdminDocumentTypes = lazy(() => import('./pages/admin/AdminDocumentTypes'));
const AdminNoticeTypes = lazy(() => import('./pages/admin/AdminNoticeTypes'));
const AdminFaqCrud = lazy(() => import('./pages/admin/AdminFaqCrud'));
const AdminStudentsDocuments = lazy(() => import('./pages/admin/AdminStudentsDocuments'));

// Lazy Shared Views
const SharedHelpCenter = lazy(() => import('./pages/shared/SharedHelpCenter'));
const SharedStudentProfile_v2 = lazy(() => import('./pages/shared/SharedStudentProfile_v2'));
const StudentPracticeFormDialog = lazy(() => import('./components/student/StudentPracticeFormDialog'));

const useStyles = makeStyles({
    success: { backgroundColor: '#0984e3' },
    info: { backgroundColor: '#666', color: 'white' },
})

const GenerateScrollbarCss = (color, alt = '') => (
    `${alt}::-webkit-scrollbar-thumb {
        border: 4px solid ${color}  !important;
    }`
)

function App() {
    const classes = useStyles();
    const api = useApi();
    const { darkMode, oledDarkMode, disableAnims, fastLoad } = useApp();
    const { user, ready, loggedIn, token } = useUser();

    const snackRef = useRef(null);

    // Data that needs to be fetched before the app can work properly
    const [cities, setCities] = useRecoilState(citiesAtom);
    const setCitiesLookup = useRecoilState(citiesLookupAtom)[1];

    let userAdmin = user?.is_admin

    const hellaReady = useMemo(() => (loggedIn) ? (ready && ((userAdmin && fastLoad) || (cities ?? false))) : ready, [fastLoad, userAdmin, ready, loggedIn, cities])

    const routes = useMemo(function () {
        if (token !== '') {
            switch (user.is_admin) {
                case true: return (
                    <Switch>
                        <Route exact path="/podjetja" component={AdminCompanies} />
                        <Route exact path="/zaposleni" component={AdminEmployees} />
                        <Route exact path="/oddelki" component={AdminGrades} />
                        <Route exact path="/dijaki" component={AdminStudents} />
                        <Route exact path="/dijaki/:id" component={AdminStudents} />
                        <Route exact path="/dijaki-dokumenti" component={AdminStudentsDocuments} />
                        <Route exact path="/dijaki-dokumenti/:id" component={AdminStudentsDocuments} />

                        <Route exact path="/sole" component={AdminSchools} />
                        <Route exact path="/programi" component={AdminPrograms} />
                        <Route exact path="/uporabniki" component={AdminModerators} />
                        <Route exact path="/dokumenti" component={AdminDocumentTypes} />
                        <Route exact path="/oznake" component={AdminNoticeTypes} />
                        <Route exact path="/faq" component={AdminFaqCrud} />

                        <Route exact path="/dijak/:id" component={SharedStudentProfile_v2} />
                        <Route exact path="/dijak/:id/praksa" component={StudentPracticeFormDialog} />

                        <Route exact path="/pomoc" component={SharedHelpCenter} />
                        <Route exact path="/iskalnik" component={PublicCompanies} />

                        <Route exact path="/pozabljeno-geslo" children={<PublicPasswordReset loggedIn />} />
                        <Route exact path="/pozabljeno-geslo/:hash" children={<PublicConfirmPasswordReset loggedIn />} />

                        <Redirect to="/oddelki" />
                    </Switch>
                )

                case false: return (
                    <Switch>
                        <Route exact path="/" component={SharedStudentProfile_v2} />
                        <Route exact path="/praksa" component={StudentPracticeFormDialog} />
                        <Route exact path="/pomoc" component={SharedHelpCenter} />
                        <Route exact path="/iskalnik" component={PublicCompanies} />

                        <Route exact path="/pozabljeno-geslo" children={<PublicPasswordReset loggedIn />} />
                        <Route exact path="/pozabljeno-geslo/:hash" children={<PublicConfirmPasswordReset loggedIn />} />

                        <Redirect to="/" />
                    </Switch>
                )

                default: return null;
            }
        } else {
            return <Switch>
                <Route exact path="/prijava" component={PublicLogin} />
                <Route exact path="/pozabljeno-geslo" component={PublicPasswordReset} />
                <Route exact path="/pozabljeno-geslo/:hash" component={PublicConfirmPasswordReset} />
                <Redirect to="/prijava" />
            </Switch>
        }
    }, [loggedIn, ready])

    const MakeBodyColor = () => commonStyles(theme).body //f9f9f9
    let theme = React.useMemo(
        () => createMuiTheme({
            palette: {
                type: (darkMode ?? false) ? 'dark' : 'light',
                primary: {
                    main: '#0984e3',
                    // main: '#125796',
                    // main: '#3771a0',
                    // main: '#a82626',
                    // main: '#2175a4',
                },
                secondary: {
                    main: '#d32020'
                },
                background: {
                    default: (darkMode ?? false) ? ((oledDarkMode ?? false) ? '#000' : '#252525') : '#f4f4f4', //f2f2f2 f4f4f4
                    paper: (darkMode ?? false) ? ((oledDarkMode ?? false) ? '#181818' : '#363636') : '#fff'
                },
            },
            typography: {
                button: {
                    fontWeight: 600,
                    fontSize: 15,
                    textTransform: 'none'
                },
                h6: {
                    fontWeight: 400
                },
                h5: {
                    fontWeight: 500,
                }
            },
            props: {
                MuiCheckbox: {
                    color: 'primary'
                },
                MuiRadio: {
                    color: 'primary'
                },
                MuiPaper: {
                    color: "default"
                },
                MuiButtonBase: {
                    disableRipple: disableAnims,
                },
                MuiButtonGroup: {
                    color: 'primary'
                },
                MuiAvatar: {
                    color: 'primary'
                },
                MuiButton: {
                    color: 'primary',
                    disableElevation: true
                },
                MuiCheckBox: {
                    color: 'primary'
                },
                MuiSwitch: {
                    color: 'primary'
                },
                MuiTabs: {
                    indicatorColor: 'primary',
                    textColor: 'primary',
                    style: {
                        minWidth: 80,
                        fontSize: 13
                    },

                }
            },
            transitions: {
                ...(disableAnims ? { create: () => 'none' } : {})
            }

        }),
        [darkMode, disableAnims, oledDarkMode]
    )

    useEffect(() => {
        if (ready == true && loggedIn) {
            api.cities.all()
                .then((res) => {
                    let temp = res.data.data.sort((a, b) => a.title.localeCompare(b.title));
                    setCities(temp)

                    let tempLookup = {};
                    temp.forEach(city => {
                        tempLookup[city.id] = city.title
                    })
                    setCitiesLookup(tempLookup)

                })
                .catch((res) => console.error('issue getting cities'))
        }
    }, [ready, loggedIn]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <style>{`
                    body, .bodyBackgroundColor { background-color: ${MakeBodyColor()} }
                
                    ${GenerateScrollbarCss(theme.palette.background.default)}
                    ${GenerateScrollbarCss(theme.palette.background.paper, '.paperScrollbar')}
                    ${GenerateScrollbarCss(commonStyles(theme).middleColor, '.middleColorScrollbar')}
                    ${GenerateScrollbarCss(theme.palette.background.paper, '.MuiPaper-root')}
                `}</style>

                <SnackbarProvider
                    classes={{
                        variantSuccess: classes.success,
                        variantInfo: classes.info
                    }}
                    ref={snackRef}
                    action={key => (<IconButton style={{ color: 'white' }} size="small" onClick={() => snackRef?.current.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>)}
                >
                    <BrowserRouter>
                        {
                            !hellaReady
                                ?
                                <LoadingApp show={true} />
                                :
                                <div id="horizontal-layout" >
                                    {(user?.is_admin) && loggedIn && <Sidebar />}
                                    <div id="vertical-layout" style={{ flex: 1 }} >
                                        <Header />
                                        <TopNoticeRow />
                                        <div id="horizontal-layout" >
                                            <div id="container" >
                                                <Suspense fallback={<Loader />}>
                                                    {routes}
                                                </Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </BrowserRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </div>
    )
}

export default App;
