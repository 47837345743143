import {
    AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles,
    Menu, Toolbar, Tooltip, Typography, useMediaQuery
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';
import HelpIcon from '@material-ui/icons/Help';
import LabelIcon from '@material-ui/icons/Label';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MenuIcon from '@material-ui/icons/Menu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WorkIcon from '@material-ui/icons/Work';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useApp } from '../../context/AppContext';
import { useUser } from '../../context/UserContext';
import commonStyles from '../../styles/commonStyle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import Logo from './Logo';

const sidebarLayoutAdmin = [
    { label: 'Podjetja', icon: <BusinessIcon />, route: '/podjetja' },
    { label: 'Zaposleni', icon: <WorkIcon />, route: '/zaposleni' },
    { label: 'Oddelki', icon: <ListAltIcon />, route: '/oddelki' },
    { label: 'Dijaki', icon: <PeopleIcon />, route: '/dijaki' },
    { label: 'Pregled Dokumentov', icon: <AssignmentTurnedInIcon />, route: '/dijaki-dokumenti' },
]

const sidebarLayoutSuper = [
    { label: 'Šole', icon: <LocalLibraryIcon />, route: '/sole' },
    { label: 'Programi', icon: <CategoryIcon />, route: '/programi', super: true },
    { label: 'Uporabniki', icon: <VerifiedUserIcon />, route: '/uporabniki' },
    { label: 'Dokumenti', icon: <AssignmentIcon />, route: '/dokumenti', super: true  },
    { label: 'Oznake', icon: <LabelIcon />, route: '/oznake', super: true  },
    { label: 'FAQ', icon: <HelpIcon />, route: '/faq', super: true },
    // { label: 'Besedišče', icon: <TextFieldsIcon />, route: '/besedisce', super: true, disabled: true },
]

const sidebarLayoutSuper2 = [
    { label: 'Iskalnik Podjetij', icon: <SearchIcon />, route: '/iskalnik', super: true },
    // { label: 'Playground', icon: <SportsEsportsIcon />, route: '/playground', super: true },
]



const sidebarWidth = 290 || 245;
const itemSmall = 75;
const betweenStart = 'md';
const betweenEnd = 'lg';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'static',
        borderRight: 'none',
        height: '100vh'
    },
    sidebar: {
        display: 'flex',
        
        borderRight: 'none',
        position: 'static',

        width: sidebarWidth,
        overflowX: 'hidden',
        
        '& *': {
            textDecoration: 'none'
        },

        [theme.breakpoints.between(betweenStart, betweenEnd)]: {
            transition: 'none',
            // overflow: 'hidden',
            maxWidth: itemSmall,
        },

        [theme.breakpoints.up('xl')]: {
            maxWidth: props => props.collapsedSidebar ? itemSmall : sidebarWidth
        },

        backgroundColor: props => (props.darkMode && props.oledDarkMode)  ? theme.palette.background.default : commonStyles(theme).middleColor,
    },
    label: {
        fontSize: 10,
        opacity: .8
    },
    
    inactive: {
           
        [theme.breakpoints.between(betweenStart, betweenEnd)]: {
            paddingBottom: 15,
        },
        '& > *': {
            minHeight: 54, 
            '& span': {
                fontWeight: 500,
                whiteSpace: 'nowrap'
            },
        },
    },
    active: {
        '& *': {
            color: theme.palette.primary.main,
        },
        '& > *': {
            '& *': {
                fontWeight: 600,
            },
        }
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 14,
        backgroundColor: (theme.palette.type === 'light') ? theme.palette.primary.main : theme.palette.background.paper,
        color: 'white',
        border: 'none',
    },

    toolbar: {
        padding: '0 7px 0 14px',
        zIndex: theme.zIndex.drawer + 11,
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        minHeight: 64,
        boxShadow: '0 1px 6px rgba(0, 0, 0, 0.21)'
    },

    beta: {
        marginLeft: 5,
        fontSize: '0.67rem',
        borderRadius: 6
    },
    title: {
        marginLeft: 2,
        color: 'white'
    },

    list: {
        flex: 1,
        overflowX: 'hidden',
        backgroundColor: props => (props.darkMode && props.oledDarkMode)  ? theme.palette.background.default : commonStyles(theme).middleColor,
        overflowY: 'auto',
        [theme.breakpoints.up('md')]: {
            borderRight: props => (theme.palette.type === 'light' || props.oledDarkMode ) ? `1px solid ${theme.palette.divider}` : 'none',
        },

        [theme.breakpoints.between(betweenStart, betweenEnd)]: {
            maxWidth: itemSmall,
        },
    }, 
    notSmall: {
        [theme.breakpoints.between(betweenStart, betweenEnd)]: {
            display: 'none',
        },
    },
    
    // Wanted to type popper but instead i typed pooper... oh well
    tooltipPooper: {
        display:  'none',
        [theme.breakpoints.between(betweenStart, betweenEnd)]: {
            display: 'initial'
        },
        [theme.breakpoints.up('xl')]: {
            display: props => props.collapsedSidebar ? 'initial' : 'none',
        },
    },

    verticalMenu: {
        '&  div': {
            minHeight: 'unset',
        },
        '& a': {
            textDecoration: 'none'
        },
    }
}))

function SidebarDivider() {
    return <Box paddingY=".5em" paddingX="21px" ><Divider /></Box>
}

export default function Sidebar() {

    const { user } = useUser();
    const { darkMode, showSidebar, toggleOpenSidebar, oledDarkMode, collapsedSidebar } = useApp();

    const mobile = useMediaQuery('(max-width: 959px)');
    const shortHeight = useMediaQuery('(max-height:770px)');

    const [anchor, setAnchor] = useState(null);
    function hideMenu() {
        setAnchor(null);
    }
    
    const verticalMenu = mobile ? false : shortHeight;

    const classes = useStyles({
        forceOpenSidebar: showSidebar,
        oledDarkMode,
        darkMode,
        collapsedSidebar
    });

    function sidebarFinalAction() {
        toggleOpenSidebar(false)
        hideMenu();
    }

    function SidebarFactory({layout, menu = false}) {

        return (<>
            {layout.map(entry => {

                if(entry.super) {
                    if(user.access_level !== 2){
                        return null;
                    }
                }

                return (
                    <NavLink key={entry.label} style={{pointerEvents: entry.disabled ? 'none' : 'all', cursor: entry.disabled ? 'default' : 'pointer'}} className={classes.inactive}  activeClassName={classes.active} to={entry.route}  onClick={(e) => {
                        if(entry?.disabled ?? false) 
                            e.preventDefault();
                        else
                            sidebarFinalAction();
                    }} >
                        <Tooltip disableHoverListener={menu} PopperProps={{
                            className: classes.tooltipPooper
                        }} key={entry.label} title={entry.label} placement="right"   >
                            <ListItem button disabled={entry?.disabled ?? false}  style={{paddingLeft: 25, }}  >
                                <ListItemIcon style={{minWidth: 50}}  > {entry.icon}</ListItemIcon>
                                <ListItemText className={classes.entryText}>{entry.label}</ListItemText>
                            </ListItem>
                            </Tooltip>
                    </NavLink>
                )
            })}
        </>)
    }

    return (
        <Drawer id="sidebar" onClose={toggleOpenSidebar} variant={mobile ? 'temporary' : 'persistent'} open={mobile ? showSidebar : true} className={classes.root + ' background'} classes={{
            paper: classes.sidebar
            }} 
        >
            <AppBar elevation={0} className={classes.appBar}  position="static"  >
                <Toolbar  variant="regular" className={classes.toolbar}  >
                    <Box display="flex" alignItems="center" flex="1" >
                        <div  >
                            <IconButton style={{marginRight: 11, color: 'white', display: mobile ? 'block' : 'none'}} onClick={() => toggleOpenSidebar()} children={<MenuIcon />} />
                        </div>
                        <Typography className={classes.title} variant="h5" component="span"  >
                            <Logo />
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>

            <List className={classes.list + ' middleColorScrollbar'} >
                <SidebarFactory layout={sidebarLayoutAdmin} />

                { !verticalMenu &&   <>
                    <SidebarDivider />
                <SidebarFactory layout={sidebarLayoutSuper} /></>}

                { !verticalMenu && <>
                <SidebarDivider />
                <SidebarFactory layout={sidebarLayoutSuper2} /></>}

                { verticalMenu && <>
                <SidebarDivider />
                <div className={classes.inactive} >
                    <Tooltip PopperProps={{
                        className: classes.tooltipPooper
                    }}  title="Več" placement="right"   >
                        <ListItem button style={{paddingLeft: 25}}  onClick={e => setAnchor(e.target)} >
                            <ListItemIcon style={{minWidth: 50}}  > <MoreHorizIcon /> </ListItemIcon>
                            <ListItemText className={classes.entryText}> Več </ListItemText>
                        </ListItem>
                    </Tooltip>
                </div>
                </>}

                

                {/* { verticalMenu && <>
                <SidebarDivider />
                <SidebarFactory layout={sidebarLayoutSuper2} /></>} */}
                
                {/* <div className={classes.notSmall} >
                    <SidebarDivider />
                    <Box  p={1} px={4} >
                        <Typography variant="caption">&copy; 2020 al neki</Typography>
                    </Box>
                </div> */}

                {/* <ListItem button className={classes.inactive} >
                    <ListItemIcon> <MoreHorizIcon /> </ListItemIcon>
                    <ListItem
                </ListItem> */}

                
            </List>

            <Menu anchorEl={anchor} className={classes.verticalMenu + ' paperScrollbar'} open={anchor !== null} onClose={hideMenu}  >
                <SidebarFactory layout={sidebarLayoutSuper} menu  />
                {/* <SidebarDivider /> */}
                {/* <SidebarFactory layout={sidebarLayoutSuper2} menu /> */}
            </Menu>

        </Drawer>
    )
}
