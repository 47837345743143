const lsKeys = {
    cachedCities: 'pud-cached-cities',
    auth: {
        token: 'pud-auth-token'
    },
    appSettings: {
        bDarkMode: 'pud-darkMode',
        bDisableAnims: 'pud-disableAnims',
        bOledDarkMode: 'pud-oledDarkMode',
        bExperimentalFeatures: 'pud-experimental',
        bFastLoad: 'pud-fastLoad',
        bNoticeTypeDescriptions: 'pud-ntDescriptions',
        bDenseTables: 'pud-denseTables',
        bCollapsedSidebar: 'pud-collapsedSidebar',

        schoolYear: 'pud-schoolYear'
    },
}

export default lsKeys;