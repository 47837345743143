import Axios from 'axios';
import { useMemo } from 'react';
import { useUser } from '../context/UserContext';
import lsKeys from '../utils/localStorageKeys';

const config = {
    host: process.env.REACT_APP_API_HOST,
}

export function setNewToken(token) {
    localStorage.setItem(lsKeys.auth.token, token);
}

export default function useApi() {

    const { token, checkTokenValidation } = useUser();

    const header = useMemo(() => ({
        Authorization: 'Bearer ' + token
    }), [token]);

    async function previewFile(url) {
        try {
            const req = new Request(url, {
                method: 'get',
                headers: new Headers({

                }),
                mode: 'cors',
                cache: 'default',

                credentials: 'include',
            });
            let response = await fetch(req);
            let blob = await response.blob();
            let blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (err) {
            console.error(err);
        }
    }

    const request = useMemo(() => ({
        get: (route, options) => Axios.get(`${config.host}/api/v2/${route}`, {
            headers: header,
            ...options
        }),
        post: (route, data, options) => Axios.post(`${config.host}/api/v2/${route}`, data, {
            ...options,
            headers: { ...header, ...options?.header },
        }),
        put: (route, data, options) => Axios.put(`${config.host}/api/v2/${route}`, data, {
            headers: header,
            ...options
        }),
        delete: (route, options) => Axios.delete(`${config.host}/api/v2/${route}`, {
            headers: header,
            ...options
        }),
    }), [token])

    const api = useMemo(() => ({
        previewFile,
        files: {
            previewBlob: () => request.get()
        },
        auth: {
            login: (email, password) => {
                // console.log({ email, password })
                return request.post('auth/login', { email, password })
            },
            logout: () => {
                localStorage.removeItem(lsKeys.auth.token);
                window.location.reload();
            },
            password_reset: (email) => request.post('password-reset', { email }),
            confirm_password_reset: (hash, password) => request.post(`password-reset/${hash}`, { password })
        },
        grades: {
            create: (data) => request.post(`grades`, data),
            read: (id) => request.get(`grades/${id}`),
            update: (id, data) => request.put(`grades/${id}`, data),
            delete: (id) => request.delete(`grades/${id}`),
            all: () => request.get(`grades`),
            import: (id, data) => request.post(`schools/${id}/import`, data,),
            export: (id) => request.get(`grade/${id}/export`, {
                responseType: 'blob',
            }),
            exportAll: (id) => request.get(`grade/${id}/export/all`, {
                responseType: 'blob',
            }),
            students: (id) => request.get(`grade/${id}/students`),
            studentsDocuments: (id) => request.get(`grade/${id}/practices`),

            documentTypes: {
                all: (id) => request.get(`grade/${id}/document-types`),
                update: (grade_id, id, data) => request.put(`grade/${grade_id}/document-types/${id}`, data),
            }
        },
        schools: {
            create: (data) => request.post(`schools`, data),
            read: (id) => request.get(`schools/${id}`),
            update: (id, data) => request.put(`schools/${id}`, data),
            delete: (id) => request.delete(`schools/${id}`),
            all: () => request.get(`schools`),

            documentTypes: {
                all: (id) => request.get(`school/${id}/document-types`),
                create: (id, data) => request.post(`school/${id}/document-types/`, data),
            }
        },
        companies: {
            create: (data) => request.post(`companies`, data),
            read: (id) => request.get(`companies/${id}`),
            update: (id, data) => request.put(`companies/${id}`, data),
            delete: (id) => request.delete(`companies/${id}`),
            all: () => request.get(`companies`),
            employees: (id) => request.get(`companies/${id}/employees`),
            nationalByTax: (tax_number) => request.get(`companies/national/${tax_number}`),
            national: () => request.get(`companies/national`),
            foreign: () => request.get(`companies/foreign`),
            merge: (data) => request.post('companies/merge', data)
        },
        employees: {
            create: (data) => request.post(`employees`, data),
            read: (id) => request.get(`employees/${id}`),
            update: (id, data) => request.put(`employees/${id}`, data),
            delete: (id) => request.delete(`employees/${id}`),
            all: () => request.get(`employees`),
            table: () => request.get(`employees/table`),
            merge: (data) => request.post('employees/merge', data)
        },
        admins: {
            create: (data) => request.post(`admins`, data),
            read: (id) => request.get(`admins/${id}`),
            update: (id, data) => request.put(`admins/${id}`, data),
            delete: (id) => request.delete(`admins/${id}`),
            all: () => request.get(`admins`),
            updateCredentials: (user, data) => request.put(`admins/${user}/credentials`, data)
        },
        programs: {
            create: (data) => request.post(`programs`, data),
            read: (id) => request.get(`programs/${id}`),
            update: (id, data) => request.put(`programs/${id}`, data),
            delete: (id) => request.delete(`programs/${id}`),
            all: () => request.get(`programs`),
            companies: (data) => request.post(`programs/companies`, data),
            getAllProgramsForCompany: (id) => request.get(`companies/${id}/programs`),
            setCompanyPrograms: (id, data) => request.post(`companies/${id}/programs`, data),
        },
        schoolYears: {
            create: (data) => request.post(`schoolyears/`, data),
            read: (id) => request.get(`schoolyears/${id}`),
            update: (id, data) => request.put(`schoolyears/${id}`, data),
            delete: (id) => request.delete(`schoolyears/${id}`),
            all: () => request.get(`schoolyears`),
        },
        users: {
            create: (data) => request.post(`users`, data),
            read: (id) => request.get(`users/${id}`),
            update: (id, data) => request.put(`users/${id}`, data),
            delete: (id) => request.delete(`users/${id}`),
            current: () => request.get('users/current'),
            updateCredentials: (user, data) => request.put(`users/${user}/credentials`, data)
        },
        cities: {
            all: () => request.get('cities')
        },
        practices: {
            create: (data) => request.post('practices', data),
            update: (id, data) => request.put(`practices/${id}`, data),
            setMentor: (id, data) => request.post(`practices/${id}/mentor`, data),
            reset: (id) => request.delete(`practices/${id}`)
        },
        documentTypes: {
            create: (data) => request.post(`document-types`, data),
            read: (id) => request.get(`document-types/${id}`),
            update: (id, data) => request.put(`document-types/${id}`, data),
            delete: (id) => request.delete(`document-types/${id}`),
            all: () => request.get(`document-types`),
        },
        documents: {
            upload: (id, data) => request.post(`users/${id}/documents`, data),
            download: (id, document_id) => request.post(`users/${id}/documents/${document_id}`),
            downloadAndPreview: (id, document_id) => request.post(`users/${id}/documents/${document_id}`, {}, {
                responseType: 'blob',
            }),
            update: (id, data) => request.put(`documents/${id}`, data),
        },
        noticeTypes: {
            create: (data) => request.post(`notice-types`, data),
            read: (id) => request.get(`notice-types/${id}`),
            update: (id, data) => request.put(`notice-types/${id}`, data),
            delete: (id) => request.delete(`notice-types/${id}`),
            all: () => request.get(`notice-types`),
        },
        faq: {
            create: (data) => request.post(`faq`, data),
            read: (id) => request.get(`faq/${id}`),
            update: (id, data) => request.put(`faq/${id}`, data),
            delete: (id) => request.delete(`faq/${id}`),
            all: () => request.get(`faq`),
            user: () => request.get(`faq/user`),
        },
        emails: {
            send: (data) => request.post(`mail/send`, data)
        }

    }), [token])

    return api;
}

export function openDocumentInNewTab() {

}