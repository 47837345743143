import {
    Box, CircularProgress, makeStyles,
    Typography
} from '@material-ui/core';
import React from 'react';



const useStyles = makeStyles((theme) => ({

    root: {
        zIndex: 9999999,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: 'rgba(0,0,0,0.3)',
        // background: 'rgba(30,30,30,1)'
        background: theme.palette.background.default
    },

}))


// This component covers the entire app until a successful initial load of all required data
export default function LoadingApp({show}) {

    const classes = useStyles();

    return (
        (show ? <Box className={classes.root} >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"  >
                <Typography variant="h2" component="h2" >PUD</Typography>
                <Typography component="h6" >Pripravljamo okolje</Typography>
                <CircularProgress size="40px" />
            </Box>
        </Box> : null)
    )
}