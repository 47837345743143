import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '4px 16px 0 8px ',
        // backgroundColor: fade(theme.palette.primary.dark, .9),
        backgroundColor: theme.palette.background.paper,
        // color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        // justifyContent: 'space-apart',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`
    },

    left: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& *:nth-child(1)': {
            marginRight: 9
        }
    }
}))

export default function TopNoticeRow() {

    const classes = useStyles();

    return null;

    return (
        <Box  className={classes.root} >

            {/* <div></div> */}
            
            <div className={classes.left}>
                <WarningIcon />
                <Typography variant="caption" >PUD IS WORKING WOOOOO</Typography>

            </div>
            <div>
                <IconButton disabled size="small" color="inherit"  >
                    <CloseIcon  />
                </IconButton>
            </div>
            {/* <div></div> */}
        
        </Box>
    )
}
