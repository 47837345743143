import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo({title}) {


    // return <>PUD</>

    return (<>
        <Link to="/">
            <img width="42" style={{paddingTop: 9, paddingLeft: 1, filter: 'brightness(0) invert(1)', }} src="/waffle.png"  />
        </Link>

        {title && 'PUD'}
    </>)
}
