import { MenuItem } from '@material-ui/core';
import {
    Avatar, Box,
    Collapse, Divider, Drawer,
    IconButton,
    Link as MuiLink, List,
    ListItem, ListItemAvatar,
    ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles,
    Switch, Tooltip, Typography, TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '../../context/AppContext';
import { useUser } from '../../context/UserContext';
import useApi from '../../services/useApi';
// import { api } from '../../services/api';

const useStyles = makeStyles((theme) => ({

   root: {
       
       overflow: 'hidden',
       '& > *': {
            overflowX: 'hidden'
        },
   },
    list: {
        width: 320,
        maxWidth: '100vw',
        paddingTop: 0,
        overflow: 'hidden',
        padding: 0
        
    },

    top: {
        // backgroundColor: theme.palette.primary.main,
        // color: 'white',
        // &: 
        // paddingTop: 14
    },

    drawerPaper: {
        // borderTopLeftRadius: 25,
        // borderBottomLeftRadius: 25,
        marginRight: -10
    },

    container: {
       
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        // padding: '0 12px 0 0px'
        padding: 0
    },

    listIcon: {
        minWidth: 30
    },

    avatar: {
        backgroundColor: theme.palette.primary.main,
         color: 'white',
        width: theme.spacing(6),
        height: theme.spacing(6)
    }

}))

const ListEntry = ({icon, text, actions, noPadding, button, onClick, tooltip}) => {

    const nopadd = {
        paddingTop: 0,
        paddingBottom:5
    }

    return <Tooltip title={tooltip ?? ''} placement="left" >
        <ListItem button onClick={onClick} style={noPadding ? nopadd : {}}  >
        {icon && <ListItemIcon style={{minWidth: 35}} >
            {icon}
        </ListItemIcon>}

        <ListItemText style={{ fontSize: '.9rem' }} disableTypography >
            {text}
        </ListItemText>

        <ListItemSecondaryAction style={{pointerEvents: 'none'}}>
            {actions}
        </ListItemSecondaryAction>
    </ListItem>
    </Tooltip>
}

export default function HeaderMenu({drawerProps}) {
    const classes = useStyles();
    const history = useHistory();

    const {
        darkMode, setDarkMode,
        oledDarkMode, setOledDarkMode,
        disableAnims, setDisableAnims,
        fastLoad, setFastLoad,
        noticeTypeDescriptions, setNoticeTypeDescriptions,
        denseTables, setDenseTables,
        collapsedSidebar, setCollapsedSidebar,

        selectedSchoolYear, setSelectedSchoolYear
    } = useApp();
    const { user } = useUser();
    const api = useApi()

    const [schoolYears, setSchoolYears] = useState([]);

    const { onClose } = drawerProps;

    useEffect(() => {
        fetchSchoolYears();
    }, [])

    async function fetchSchoolYears() {
        try {
            let req = await api.schoolYears.all();
            setSchoolYears(req.data.data);
        } catch(e) {
            console.log(e);
        }
    }
    
    return (<>
        <Drawer className={classes.root} {...drawerProps} anchor="right" classes={{
            paper: classes.drawerPaper
        }} >
            
            <div in={drawerProps.open} direction="left" timeout={{
                enter: 260,
                exit: 100
            }}  >
            <div className={classes.container} >
                <div className={classes.list} >
                    <List>
                        
                        <ListItem>
                            <ListItemText>
                                <Typography variant="button">Moj Račun</Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton onClick={(e) => onClose ?  onClose(e, "backdropClick") : null} >
                                    <ClearIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <div className={classes.top} >
                            <ListItem >
                                <ListItemAvatar  >
                                    <Avatar className={classes.avatar} >{user?.first_name[0]}</Avatar>
                                </ListItemAvatar>

                                <ListItemText>
                                    <div style={{fontSize: '.9rem'}}>
                                        <div style={{fontSize: '1rem'}} >{user?.first_name} {user?.last_name}</div>
                                        <div  >{user?.email}</div>
                                        <div></div>
                                    </div>

                                </ListItemText>
                            </ListItem>
                            
                            <Box pl="5em">
                                <MuiLink href="#" onClick={api.auth.logout} >
                                    Odjava
                                </MuiLink>

                                <span style={{marginTop: 6, marginLeft: 8}} />
                                
                                <MuiLink href="#" onClick={() => {
                                    history.push('/pozabljeno-geslo');
                                    onClose();
                                }} >
                                    Spremeni Geslo
                                </MuiLink>
                            </Box>

                        </div>

                        <Box py={1} pt={2}></Box>
                        
                        {/* { user?.is_admin &&  <>
                        <ListItem>
                                <ListItemText>
                                    <Typography variant="button">Šolsko Leto</Typography>
                                </ListItemText>
                            </ListItem>
                            
                            <Box px={2} pr={3} pt={0} pb={3} >
                                {(user?.is_admin ?? false) && <>
                                    <TextField select size="small"  variant="filled" fullWidth label="Izberi Šolsko Leto" placeholder="Šolsko leto" value={selectedSchoolYear} onChange={(e) => setSelectedSchoolYear(e.target.value)} >
                                        {schoolYears.map(_sy => <MenuItem value={_sy.id} key={_sy.id} >{_sy.title}</MenuItem>)}
                                    </TextField>
                                </>}
                            </Box>
                        </>} */}
                        
                        <ListItem>
                            <ListItemText>
                                <Typography variant="button">Nastavitve</Typography>
                            </ListItemText>
                        </ListItem>
                        
                        <ListEntry
                            // noPadding
                            text="Temni Način"
                            onClick={() => setDarkMode(!darkMode)}
                            actions={
                                <Switch  checked={darkMode}  /> 
                            }
                        />

                        <Collapse in={darkMode} >
                            <ListEntry
                                text="OLED Tema"
                                onClick={() => setOledDarkMode(!oledDarkMode)}
                                actions={
                                    <Switch  checked={oledDarkMode}  /> 
                                }
                            />
                        </Collapse>
                        
                        <ListEntry
                            // noPadding
                            onClick={() => setDisableAnims(!disableAnims)}
                            text="Animacije"
                            actions={
                                <Switch  checked={!disableAnims}  /> 
                            }
                        />

                        {(user?.is_admin ?? false) && <>
                        
                            {/* <Divider /> */}
                            
                            <ListEntry
                                // noPadding
                                tooltip="Določa ali so vidni opisi značk pri dokumentih"
                                text="Opisi značk"
                                onClick={() => setNoticeTypeDescriptions(!noticeTypeDescriptions)}
                                actions={
                                    <Switch  checked={noticeTypeDescriptions}  /> 
                                }
                            /> 

                            <ListEntry
                                text="Goste tabele"
                                onClick={() =>setDenseTables(!denseTables)}
                                actions={
                                    <Switch checked={denseTables}  /> 
                                }
                            /> 

                            <ListEntry
                                text="Skrčen meni"
                                onClick={() => setCollapsedSidebar(!collapsedSidebar)}
                                actions={
                                    <Switch checked={collapsedSidebar}  /> 
                                }
                            /> 
{/* 
                            <ListEntry
                                // noPadding
                                text="Eksperimentalne Vsebine *"
                                onClick={() => setExperimentalFeatures(!experimentalFeatures)}
                                actions={
                                    <Switch  checked={experimentalFeatures}  /> 
                                }
                            />  */}

                            <ListEntry
                                // noPadding
                                tooltip="* Hitro nalaganje lahko povzroči manjkajoč prikaz določenih podatkov"
                                text="Hitro nalaganje *"
                                onClick={() => setFastLoad(!fastLoad)}
                                actions={
                                    <Switch  checked={fastLoad}  /> 
                                }
                            />
                        </>}

                        {/* <Divider /> */}
                    </List>
                </div>

                {/* <div style={{padding: 5, paddingLeft: 17, paddingBottom: 10}}>
                        <Typography variant="caption"  >{process.env.REACT_APP_DEPLOY_ID ?? 'DEV BUILD'}</Typography>
                </div> */}
            </div>
            </div>
        </Drawer>
    </>)
}
